import {Button} from 'react-bootstrap';
import {Right} from 'shared/icons/Homepage';

import './PlanCard.scss';
import {PlanDetail} from '../PlansListHeader/PlansListHeader';

const PlanCard = ({
    isFirst,
    plantDetails,
    isAnnual,
}: {
    isFirst: boolean;
    isAnnual: boolean;
    plantDetails: PlanDetail;
}) => {
    const isPopular = plantDetails.isPopular;
    const isGetStarted = plantDetails.isGetStarted;

    return (
        <div
            style={{backgroundColor: isFirst ? '#F9F9F9' : isPopular ? '#0991F2' : 'white'}}
            className="PlanCardContainer"
        >
            <div className="PlanCardContainer__MostPopular">
                <p className="mb-0">{isPopular && 'Most Popular'}</p>
            </div>
            <div
                style={{backgroundColor: isFirst ? '#F9F9F9' : 'white'}}
                className={`PlanCardContainer__PlanCard ${isPopular && 'PlanCardContainer__Popular-PlanCard'}`}
            >
                <div className="PlanCardContainer__PlanCard__header">
                    <img src={plantDetails.icon} alt="planicon" />
                    <p style={{color: isFirst ? '#212529' : isPopular ? '#0A8BF2' : '#788B9A'}} className="mb-0">
                        {plantDetails.name}
                    </p>
                </div>
                <div className="PlanCardContainer__PlanCard__heading">
                    <p style={{color: isPopular ? '#151C2D' : '#788B9A'}} className="mb-0">
                        {plantDetails.heading}
                    </p>
                </div>
                <div className="PlanCardContainer__PlanCard__pricing">
                    <span className="PlanCardContainer__PlanCard__pricing--currencysign">$</span>
                    <span className="PlanCardContainer__PlanCard__pricing--price">{plantDetails.price}</span>
                    {plantDetails.price ? (
                        <span className="PlanCardContainer__PlanCard__pricing--time">
                            {isAnnual ? '/year' : '/month'}
                        </span>
                    ) : null}
                </div>
                <div className={`${isGetStarted ? 'PlanCardContainer__PlanCard__getstartedbtn' : ''}`}>
                    {isGetStarted && (
                        <Button className="m-auto" variant="featured" block>
                            Get started with {plantDetails.name}
                        </Button>
                    )}
                </div>

                <ul className="PlanCardContainer__PlanCard__perkslist">
                    {plantDetails.perks.map((perk, index) => {
                        return (
                            <li key={index} className="PlanCardContainer__PlanCard__perkslist__perk">
                                <div
                                    style={{
                                        backgroundColor: isPopular ? '#0A8BF2' : '#788B9A',
                                    }}
                                    className="PlanCardContainer__PlanCard__perkslist__perk--tickicon"
                                >
                                    <img src="/assets/SVG/right.svg" alt="tickicon" />
                                </div>
                                <p
                                    style={{color: isPopular ? '#0A8BF2' : '#788B9A'}}
                                    className="PlanCardContainer__PlanCard__perkslist__perk--pertext mb-0"
                                >
                                    {perk}
                                </p>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default PlanCard;
