import {Tab, Tabs} from 'react-bootstrap';
import PlanCard from '../PlanCard/PlanCard';

import './PlansList.scss';
import {PlanDetail} from '../PlansListHeader/PlansListHeader';

interface IPlansList {
    annualyPlans: PlanDetail[];
    monthlyPlans: PlanDetail[];
}

const PlansList = ({annualyPlans, monthlyPlans}: IPlansList) => {
    return (
        <div className="Plans">
            <Tabs defaultActiveKey="monthly" className="Plans__ClientService-tab">
                <Tab eventKey="monthly" title="Monthly" className="Plans__ClientService-tab--tabpane">
                    {monthlyPlans.map((plantDetails, index) => {
                        return (
                            <PlanCard key={index} isFirst={index === 0} plantDetails={plantDetails} isAnnual={false} />
                        );
                    })}
                </Tab>
                <Tab eventKey="annually" title="Annually" className="Plans__ClientService-tab--tabpane">
                    {annualyPlans.map((plantDetails, index) => {
                        return <PlanCard key={index} isFirst={index === 0} plantDetails={plantDetails} isAnnual />;
                    })}
                </Tab>
            </Tabs>
        </div>
    );
};

export default PlansList;
