import {Tab, Tabs} from 'react-bootstrap';

import './PlansListHeader.scss';
import PlansList from '../PlantList/PlansList';
import {useSignupStatus} from 'modules/profile/hooks/useSignupStatus';
import {Role} from 'modules/profile/models/IProfile';
import {useAuth} from 'shared/auth/hooks/useAuth';

export interface PlanDetail {
    name: string;
    icon: string;
    heading: string;
    isPopular: boolean;
    price: number;
    perks: string[];
    isGetStarted: boolean;
}

const PlansListHeader = () => {
    const {profile} = useSignupStatus();
    const isProvider = profile?.role === Role.Provider || profile?.role === Role.System;
    const isClient = profile?.role === Role.Client;
    const {isAuthenticated} = useAuth();

    const clietnPlans = {
        monthly: [
            {
                name: 'Free',
                icon: '/assets/plans/free.svg',
                heading: 'Free means free and shall forever be',
                isPopular: false,
                isGetStarted: false,
                price: 0,
                perks: [
                    'Free profile search',
                    'Free jobs post',
                    'Free bookings with lawyers',
                    'Free messaging',
                    'Free legal project invites',
                    'Free talent lists',
                    'Free slack community membership',
                    '1 free legal document',
                    '1 free e-signature per month',
                ],
            },
            {
                name: 'Premium',
                icon: '/assets/plans/basic.svg',
                heading: 'Swim with sharks',
                isPopular: true,
                isGetStarted: true,
                price: 39,
                perks: [
                    'Everything in Free',
                    'Legal Project Owner Status',
                    'Unlimited legal doc generations - access complete library',
                    'Unlimited e-signatures',
                    'Unlock exclusive legal content channels in slack',
                    'Exclusive Partner Offers',
                    'Support with lawyer recommendations',
                ],
            },
        ],
        annually: [
            {
                name: 'Free',
                icon: '/assets/plans/free.svg',
                heading: 'Free means free and shall forever be',
                isPopular: false,
                isGetStarted: false,
                price: 0,
                perks: [
                    'Free profile search',
                    'Free jobs post',
                    'Free bookings with lawyers',
                    'Free messaging',
                    'Free legal project invites',
                    'Free talent lists',
                    'Free slack community membership',
                    '1 free legal document',
                    '1 free e-signature per month',
                ],
            },
            {
                name: 'Premium',
                icon: '/assets/plans/basic.svg',
                heading: 'Swim with sharks',
                isPopular: true,
                isGetStarted: true,
                price: 400,
                perks: [
                    'Everything in Free',
                    'Legal Project Owner Status',
                    'Unlimited legal doc generations - access complete library',
                    'Unlimited e-signatures',
                    'Unlock exclusive legal content channels in slack',
                    'Exclusive partner offers',
                ],
            },
        ],
    };

    const serviceProviderPlans = {
        monthly: [
            {
                name: 'Free',
                icon: '/assets/plans/free.svg',
                heading: 'Free means free and shall forever be',
                isPopular: false,
                isGetStarted: false,
                price: 0,
                perks: [
                    'Free Marketplace listing',
                    'Free Messaging & Bookings',
                    'Free Legal Project Invites',
                    'Free slack community membership',
                    '1 Free Legal Doc',
                    '1 Free e-signature a month',
                    'Jobs Post/Replies',
                ],
            },
            {
                name: 'Basic',
                icon: '/assets/plans/basic.svg',
                heading: "Dip your toes, but don't get wet",
                isPopular: false,
                isGetStarted: true,
                price: 49,
                perks: [
                    'Everything in Free',
                    'Google/outlook calendar integration',
                    'Legal Project Owner status',
                    'Unlimited legal document Generation - access complete library',
                    'Unlimited e-signatures',
                    'Case charts',
                    'Exclusive CPD Channels in slack and webinar promotion',
                    'Practice scale up help - 3 recruitments a year/special offers/firm marketing',
                    'Prime marketplace position',
                ],
            },
            {
                name: 'Pro',
                icon: '/assets/plans/pro.svg',
                heading: 'Dive 20,000 leagues under the sea',
                isGetStarted: true,
                isPopular: true,
                price: 99,
                perks: ['Everything in Basic', '2 guaranteed leads a month'],
            },
        ],
        annually: [
            {
                name: 'Free',
                icon: '/assets/plans/free.svg',
                heading: 'Free means free and shall forever be',
                isPopular: false,
                isGetStarted: false,
                price: 0,
                perks: [
                    'Free marketplace listing',
                    'Free messaging & Bookings',
                    'Free legal project invites',
                    'Free slack community membership',
                    '1 Free Legal Doc ',
                    '1 Free E-signature a month',
                    'Jobs Post/Replies',
                ],
            },
            {
                name: 'Basic',
                icon: '/assets/plans/basic.svg',
                heading: "Dip your toes, but don't get wet",
                isPopular: false,
                isGetStarted: true,
                price: 530,
                perks: [
                    'Everything in Free',
                    'Google/Outlook calendar integration',
                    'Project Owner status',
                    'Unlimited Doc Gen - access to complete library',
                    'Unlimited e-signatures',
                    'Case Charts',
                    'Exclusive CPD Channels in slack and webinar promotion',
                    'Practice scale up help - 3 recruitments a year/special offers/firm marketng',
                    'Prime marketplace position',
                ],
            },
            {
                name: 'Pro',
                icon: '/assets/plans/pro.svg',
                heading: 'Dive 20,000 leagues under the sea',
                isGetStarted: true,
                isPopular: true,
                price: 1000,
                perks: ['Everything in Basic', '2 guaranteed leads a month'],
            },
        ],
    };

    return (
        <div className="PlansHeader">
            {isClient && <PlansList monthlyPlans={clietnPlans.monthly} annualyPlans={clietnPlans.annually} />}
            {isProvider && (
                <PlansList monthlyPlans={serviceProviderPlans.monthly} annualyPlans={serviceProviderPlans.annually} />
            )}
            {!isAuthenticated && (
                <Tabs defaultActiveKey="client" className="PlansHeader__ClientService-tab">
                    <Tab eventKey="client" title="Client">
                        <PlansList monthlyPlans={clietnPlans.monthly} annualyPlans={clietnPlans.annually} />
                    </Tab>

                    <Tab eventKey="service-provider" title="Service Provider">
                        <PlansList
                            monthlyPlans={serviceProviderPlans.monthly}
                            annualyPlans={serviceProviderPlans.annually}
                        />
                    </Tab>
                </Tabs>
            )}
        </div>
    );
};

export default PlansListHeader;
