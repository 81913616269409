import {Container} from 'react-bootstrap';
import {PageHelmet} from 'shared/components/PageHelmet/PageHelmet';
import {useNavbar} from 'shared/hooks/useNavbar';
import PlansListHeader from './PlansListHeader/PlansListHeader';

import './style.scss';
import PlansAndFeatures from './PlansAndFeatures/PlansAndFeatures';
import FAQ from './FAQ/FAQ';
import {AppFooter} from 'shared/components/AppFooter/AppFooter';

const PlansPage = () => {
    useNavbar(true);
    const title = 'Plans';

    return (
        <div>
            <PageHelmet title={title} description="Find your plan." />
            <Container className="PlansPage">
                <div className="PlansPage__heading">
                    <h2 className="mb-0 PlansPage__heading--text">We’ve got a plan for you.</h2>
                </div>
                <div className="PlansPage__paragraph">
                    <p className="PlansPage__paragraph--text">Pay by the month or year, cancel anytime.</p>
                </div>
                <PlansListHeader />
                <PlansAndFeatures />
                <FAQ />
            </Container>
            <AppFooter />
        </div>
    );
};

export default PlansPage;
